import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-aviator-page',
  templateUrl: './aviator-page.component.html',
  styleUrls: ['./aviator-page.component.scss']
})
export class AviatorPageComponent implements OnInit {
  userDetails:any;
  iframe_url:any;
  constructor(
    public sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    if(this.userDetails){
      var url = "https://paisaaviator.fantasylineups.com/?host_id=paisauser&token="+this.userDetails.verifytoken;
      this.iframe_url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    
  }
  back(){
    window.history.back(); 
  }

}
