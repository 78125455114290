<div class="loginPage"  >
    <div class="cancelicon" [routerLink]="['/dashboard']">
        <i class="material-icons">cancel</i>
    </div>
    <div class="login-main">

        <div class="login-inner">
            <div *ngIf="logo!='telegrambook'" class="logo text-center">
                <img src="assets/logo/localhost.png"  alt="localost">
            </div>
            <!-- register -->
            <div *ngIf="step===1" class="form">
                <form class="login-form" [formGroup]='registerForm' (ngSubmit)='onRegisterClick()'>
                    <div *ngIf="logo==='telegrambook'" class="form-group" appearance="fill">
                        <label class="form-label">Username</label>
                        <input pattern="\S(.*\S)?" type="text" class="form-control" placeholder="Enter Username" [(ngModel)]="username"
                            formControlName='username' (keyup)="remveSpace($event)" style="text-transform:uppercase">
                        <mat-icon matSuffix>person_outline</mat-icon>
                    </div>

                    <div class="form-group" appearance="fill">
                        <label class="form-label">Name</label>
                        <input type="text" class="form-control" placeholder="Enter Name" formControlName='username'>
                        <mat-icon matSuffix>person_outline</mat-icon>
                    </div>

                    <div class="form-group" appearance="fill">
                        <label class="form-label">Mobile No.</label>
                        <input type="text" class="form-control" placeholder="Enter Mobile number"formControlName='phone'>
                        <mat-icon matSuffix>mobile_friendly</mat-icon>
                    </div>

                    <div class="form-group" appearance="fill">
                        <label class="form-label">Password</label>
                        <input [type]="showPassword ? 'text' : 'password'" placeholder="Enter Password" class="form-control"
                            formControlName='password'>
                        <mat-icon style="cursor:pointer;" matSuffix [class.hide]="showPassword"
                            (click)="showPassword = !showPassword">visibility</mat-icon>
                        <mat-icon style="cursor:pointer;" matSuffix [class.hide]="!showPassword"
                            (click)="showPassword = !showPassword">visibility_off</mat-icon>
                    </div>

                    <div class="form-group" appearance="fill" *ngIf="!codesataus">
                        <label class="form-label">Referal Code: <span style="color:red;">Note: optional</span></label>
                        <input type="text" class="form-control" placeholder="Enter Referal Code"formControlName='referal_code'>
                    </div>
                    
                    <div class="form-group" appearance="fill"*ngIf="codesataus" >
                        <label class="form-label">Referal Code:</label>
                        <input type="text" class="form-control" value="{{code}}" formControlName={{code}} readonly>
                    </div>

                    <div class="submit-btn">
                        <button mat-raised-button  [disabled]="registerButtonDisable"><span
                                style="font-size:17px;">Register </span></button>
                    </div>

                </form>
            </div>
            <!-- verifyOtp -->
            <div *ngIf="step===2" class="form">
                <form class="login-form" [formGroup]='varifyOtpForm' (ngSubmit)='onVerifyOtpClick()'>

                    <div class="form-group" appearance="fill">
                        <label class="form-label">Mobile No.</label>
                        <input type="text" class="form-control" formControlName='phone' disabled>
                        <mat-icon matSuffix>mobile_friendly</mat-icon>
                    </div>

                    <div class="form-group" appearance="fill">
                        <label class="form-label">OTP</label>
                        <input [type]="showPassword ? 'text' : 'password'" class="form-control" formControlName='otp'>
                        <mat-icon style="cursor:pointer;" matSuffix [class.hide]="showPassword"
                            (click)="showPassword = !showPassword">visibility</mat-icon>
                        <mat-icon style="cursor:pointer;" matSuffix [class.hide]="!showPassword"
                            (click)="showPassword = !showPassword">visibility_off</mat-icon>
                    </div>
                    <div *ngIf="showCountdown">
                        <p>Resend OTP: {{ countdown }}</p>
                      </div>
                    <div *ngIf="!showCountdown" class=" font-weight-bold" style="cursor:pointer;margin-bottom: 30px;margin-top:-10px;"><span style="padding-left:0px;" (click)="resendhandleSingleLoginClick()">Resend OTP</span></div>
                    
                    <div class="submit-btn">
                        <button mat-raised-button color="primary" [disabled]="otpButtonDisable"><span
                                style="font-size:17px;">Verify OTP </span><mat-icon matSuffix
                                class="material-icons">how_to_reg</mat-icon></button>
                    </div>

                </form>

            </div>

        </div>

        <div class="row" style="margin: 15px 10px;float: right;"  *ngIf="logo=='clubosg' || logo=='clubprt'">

   <div class="row" style="margin: 15px 10px;float: right;">
    <div class="text-center support-icon">
      <a *ngIf="url_youtube" href="{{url_youtube}}" style="color: #ffc701">
        <i class='fab fa-youtube fa-2x'></i>&nbsp;&nbsp;&nbsp;&nbsp;
      </a>
      <a  *ngIf="url_insta" href="{{url_insta}}" style="color: #ffc701">
        <i class='fab fa-instagram fa-2x'></i>&nbsp;&nbsp;&nbsp;&nbsp;
      </a>
      <a  *ngIf="url_telegram" href="{{url_telegram}}" style="color: #ffc701">
        <i class='fab fa-telegram fa-2x'></i>&nbsp;&nbsp;&nbsp;&nbsp;
      </a>
    </div>
  </div> 

    </div>

</div>