import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { Socket } from "ngx-socket-io";
import { UsersService } from "../services/users.service";
import { Subject } from "rxjs";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  providers: [HeaderComponent, FooterComponent],
})
export class DashboardComponent implements OnInit, OnDestroy {
  offer_data: any;
  banner_img: any;
  marquetext: any;
  homeData: any;
  walletuser:any;
  dataLength: number;
  cricketData: any;
  soccerData: any;
  tennisData: any;
  virtualCricketData: any;
  ballbyballData:any;
  moment: any = moment;
  inplay:any=false;
  selectedSportData: any;
  userDetails: any;
  targetElement: Element;
  tokenCheck: boolean = false;
  avaialablePlayBattle:any;
  avaialableContest:any;
  logo:any;
 currentUrl: string = '';

  user:any;
  support_nO:any;
  logintokenscreen:any;
  modalRefcancel: BsModalRef;
  aviatorArr: any;
  aviatorlog: any;
  ballArr: any;
  ballbycricket: any;
  support: any;
  wallet_status: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private socket: Socket,
    private usersService: UsersService,
    private modalService: BsModalService,
  ) {
    this.getBanner();
    
    this.route.params.subscribe((params) => {
      if (params.sportName === undefined) {
        if (sessionStorage.getItem("loginStatus") === "true") {
          this.homeSoc();
          this.settings();
        } else {
          this.homeFreeSoc();
          this.tokenCheck = true;
        }
      } else if (
        params.sportName === "inplay" &&
        sessionStorage.getItem("loginStatus") === "true"
      ) {
        this.Inplay();
        this.inplay=true;
        this.settings();
      }
    });
   
  }

  
  async getDetials() {
    try {
      const data = await JSON.parse(sessionStorage.getItem("userDetails")); 
      this.avaialablePlayBattle=data?.details?.availableEventTypes.indexOf("3901");
      this.avaialableContest=data?.details?.availableEventTypes.indexOf("3902");
      
      return data;
    } catch (e) {
      return null;
    }
  }
  
  isInPlayRoute(): boolean {
    return this.router.url === '/dashboard/inplay';
  }
  home() {
    this.router.navigate(["home"]);
  }

  async ngOnInit(): Promise<void> {
    this.targetElement = document.querySelector("html");
    const usrDetails = await this.getDetials();
    this.user = usrDetails;
    this.currentUrl = this.router.url;
    //console.log('Current URL:', this.currentUrl);
   // this.getusertoken();
   // this.getUserBalance(); 
   //this.getSupport(); 
   
  }
  // async settings(){
  //   this.userDetails = await this.getDetials();
  //   var data = {user_id:this.userDetails?.details?._id}
  //  // console.log(this.userDetails);
  //   this.usersService.SocketPost("menusetting",data).subscribe((res: any) => {

  //     this.ballArr = res?.user?.availableEventTypes;
  //     if (this.ballArr && Array.isArray(this.ballArr) && this.ballArr.includes('b9')) {
  //       if(this.currentUrl=='/dashboard/inplay' ){
  //        this.ballbycricket='true';
  //       }
  //   }
  //     if(this.ballArr && Array.isArray(this.ballArr) && this.ballArr.includes('aviator')){
  //     this.aviatorlog='true';
  //   }
  //   });
  // }
  async settings() {
    this.userDetails = await this.getDetials();
    const data = { user_id: this.userDetails.details._id };
  
    this.usersService.SocketPost("menusetting", data).subscribe((res: any) => {
      var available_events = res;
      if (available_events) {
        //this.wallet_status=available_events?.manager?.wallet_status;
        var available_event = available_events.eventTypes;
        const available_user = available_events.user.availableEventTypes;
  
        // Map event types to menu variables
        const eventMap = {
          'b9': 'ballbycricket',
          'aviator' : 'aviatorlog'
        };
  
        // Initialize menu visibility
        Object.keys(eventMap).forEach((id) => {
          const menuKey = eventMap[id];
          const isEventAvailable = available_user.includes(id);
          const event = available_event.find((data) => data.eventType.id === id);
          console.log(isEventAvailable);
          this[menuKey] = event?.visible_status && isEventAvailable;
          
        });
      }
    });
  }
  
  public cricket(section: string) {
    window.location.hash = '';
    window.location.hash = section;
}
  
  openDialog2(myModal: TemplateRef<any>) {
    localStorage.setItem('firstVisit', 'result');
    this.modalRefcancel = this.modalService.show(
      myModal,
      Object.assign({}, { class: 'success modal-lg' })
    );
  }


  myRefreshEvent(event: Subject<any>, message: string) {
    setTimeout(() => {
      alert(message);
      event.next();
    }, 3000);
  }

  alert(message: string) {
    alert(message);
  }

  async Inplay() {
    this.userDetails = await this.getDetials();
    const data = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        token: this.userDetails.verifytoken,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      filter: {
        managers: this.userDetails.details.manager,
        eventTypeId: { $nin: ["t9", "4321"] },
        visible: true,
        deleted: false,
        marketType: { $in: ["MATCH_ODDS", "TOURNAMENT_WINNER"] },
        "marketBook.status": { $ne: "CLOSED" },
        $or: [
          { "marketBook.inplay": true },
          // { openDate: { $lte: new Date() } },
        ],
      },
      sort: { openDate: 1 },
    };
// console.log(data);

    this.socket.emit("get-inplay-markets", data);

    this.socket.on(
      "get-inplaymarkets-success",
      function (data: any) {
        this.dataLength =
          data[0].length || data[1].length || data[2].length || data[3];
        if (data) { 
          this.cricketData = data[0];
          this.soccerData = data[1];
          this.tennisData = data[2];
          this.virtualCricketData = data[3];
          this.checkVirtualShow();
         // this.getUserBalance();
        }
      }.bind(this)
    );
  }

  checkVirtualShow() {
    if (
      this.virtualCricketData?.length &&
      this.virtualCricketData[0]?.managerStatus[
        this.userDetails.details.manager
      ] === true
    ) {
      return true;
    } else {
      return false;
    }
  }
  

  async homeSoc() {
    this.userDetails = await this.getDetials();
    let data = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        token: this.userDetails.verifytoken,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      filter: {
        managers: this.userDetails.details.manager,
        eventTypeId: { $nin: ["t9", "4321"] },
        visible: true,
        deleted: false,
        marketType: { $in: ["MATCH_ODDS", "TOURNAMENT_WINNER"] },
        "marketBook.status": { $ne: "CLOSED" },
      },
      sort: { openDate: 1 },
    };

    this.socket.emit("get-home-markets", data);

    this.socket.on(
      "get-homemarkets-success",
      function (data: any) {
        this.dataLength = data.length;
        if (data) {
         // console.log(data);
          this.cricketData = data[0];
          this.soccerData = data[1];
          this.tennisData = data[2];
          this.dataLength = data[0].length || data[1].length || data[2].length;
          //this.getUserBalance();
          this.marqueText();
        }
      }.bind(this)
    );
  }

  async homeFreeSoc() {
    let data = {
      filter: {
        managers: "EXCH",
        eventTypeId: { $nin: ["t9", "4321"] },
        visible: true,
        deleted: false,
        marketType: { $in: ["MATCH_ODDS", "TOURNAMENT_WINNER"] },
        "marketBook.status": { $ne: "CLOSED" },
      },
      sort: { openDate: 1 },
    };

    this.socket.emit("get-free-home-markets", data);

    this.socket.on(
      "get-freehomemarkets-success",
      function (data: any) {
        this.dataLength = data.length;
        if (data) {
          this.cricketData = data[0];
          this.soccerData = data[1];
          this.tennisData = data[2];
          this.dataLength = data[0].length || data[1].length || data[2].length;
        //  console.log(this.soccerData);
        }
      }.bind(this)
    );
  }

  async findHostName() {
    return window.location.hostname;
  }

  async getHost() {
    const hostname = await this.findHostName();
    const splithostname = hostname.split(".");
    this.logo = splithostname[0];
    this.setManager(splithostname[0]);
  }

  setManager(hostname: any) {
    if (hostname === "clubprt") {
      this.getOffer("PRTCLUB");
    } else if (hostname === "ferrariclubb") {
      this.getOffer("FERRARICLUB");
    } else if (hostname === "clubaob") {
      this.getOffer("AOBCLUB");
    } else if (hostname === "dlexch") {
      this.getOffer("DLCLUB");
    } else if (hostname === "fairbets247") {
      this.getOffer("FAIRBET");
    } else if (hostname === "paisaexch") {
      this.getOffer("");
    } else if (hostname === "clubosg" || hostname === "dubaiclub247") {
      this.getOffer("OSGCLUB");
    } else if (hostname === "clublucky7") {
      this.getOffer("PRTLUCKY");
    }
    else if (hostname === "telegrambook") {
      this.getOffer("TELEGRAMBOOK");
    }
    else if (hostname === "kitbook") {
      this.getOffer("KITBOOK");
    }
    else {
      this.getOffer("OSGMANAGER");
    }
  }

  getBanner() {
    this.getHost();
    this.usersService.Get("get-banner/").subscribe((response: any) => {
      console.log(
        "🚀 ~ file: dashboard.component.ts:256 ~ DashboardComponent ~ this.usersService.Get ~ response:",
        response
      );

      this.banner_img = [];
      if (response.error) {
        this.toastr.error(response.message, "Error!");
      } else {
        this.banner_img = response.response;
      }
    });
  }

  getOffer(manager_name) {
    const data = { filter: { manager: manager_name, visible: true } };
    this.usersService
      .post_api("get-offer/", data)
      .subscribe((response: any) => {
        this.offer_data = [];
        if (response.error) {
          this.toastr.error(response.message, "Error!");
        } else {
          this.offer_data = response.data;
        }
      });
  }

  marqueText() {
    const data = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
    };
    // console.log(data);
    this.socket.emit("get-message", data);

    this.socket.on(
      "get-message-success",
      function (data: any) {
        // console.log(data);
        
        if (data) {
          // this.marquetext = data[0]?.message;
          this.marquetext = data?.message;
        }
      }.bind(this)
    );
  }

  

  goToDashboard(sportName, id, data) {
    if (id == -100) {
      this.router.navigate(["casino"]);
    } else if (id < -100) {
      this.router.navigate([
        "snova/" + id + "/" + data.name + "/" + data.providerCode,
      ]);
    } else {
      this.router.navigate(["dashboard/" + sportName]);
    }
  }

  checkInplayStatus(value): boolean {
    let CurrentDate = new Date();
    const currDate = CurrentDate.toISOString();
    if (currDate > value) {
      return true;
    } else {
      return false;
    }
  }

  openCasino(gameID:string)
  {
    const data={gameId:gameID}; 
    localStorage.setItem('casinoDb',JSON.stringify(data));
    // this.route.navigate(['./casino-url']);
    this.router.navigate(["./casino-url"]);
    
  }

  getSupport(){
    let data
    let domain=window.location.hostname;
      
    if (this.userDetails == null) {
      data = {
        loginStatus: false,
        domain: domain,
      }
    }else{
      data = {
        user_id: this.userDetails.details._id,
        loginStatus: true,
        domain: domain,
      }
    }
console.log(data);
    this.usersService.SocketPost("get-support ", data).subscribe((res: any) => {
      if (res) {
        this.support = res.data;
        this.support_nO = res.no_data;

      }
      else {
        // console.warn(res.message);
      }
    });
  }
  matchDetail(eventId, eventTypeId) {
    this.router.navigate(["match-detail", eventId, eventTypeId]);
  }

  virtualDetial(eventId,eventTypeId) {
    this.router.navigate(["virtual-detail", eventId, eventTypeId]);
  }

  onClickofSport(sport) {
    this.selectedSportData = sport;
  }

  goToInplay(sportName) {
    this.router.navigate(["dashboard/" + sportName]);
  }
 

  ngOnDestroy() {
    this.socket.removeAllListeners();
  }
}
