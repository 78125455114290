<!-- <div id="hidemobheadfoot"> <app-header></app-header></div>

<iframe class="bbbvideowrapvido" *ngIf="iframe_url" id="fp_embed_player"
                        [src]="iframe_url" marginwidth="0" marginheight="0" frameborder="0" width="100%" height= "100%" scrolling="no"
                        allowfullscreen="allowfullscreen" allow="autoplay" disableremoteplayback playsinline muted
                        autoplay preload="auto">
                    </iframe> -->
                    <div id="hidemobheadfoot"> <app-header></app-header></div>
                    <main id="main" class="full-wrap aviatorgapepage casinoviewpage">
                      <div class="backtabmob" style="background: rgb(52, 52, 53);">
                        <span (click)="back()" class="pagehome"><i class="material-icons" style="color:#fff; padding:8px 0px;">arrow_back_ios</i></span>
                      </div>
                      <div class="row m-0">
                        <div class="col-center col-12">
                          <div style="width: 100%;height: 100%;" *ngIf="iframe_url">
                            <iframe [src]="iframe_url" marginwidth="0" marginheight="0" frameborder="0" scrolling="yes" allow="autoplay; encrypted-media; fullscreen"
                              allowfullscreen="allowfullscreen"></iframe>
                          </div>
                          <div class="col s12 p-0 main-tabs">
                            <!---after slider tabs-->
                            <app-right-side-bar></app-right-side-bar>
                          </div>
                        </div>
                      </div>
                      <div id="hidemobheadfoot"> <app-footer></app-footer></div>
                    </main>