import { Component, OnInit , TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../services/users.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-wallet-deposit-withdraw',
  templateUrl: './wallet-deposit-withdraw.component.html',
  styleUrls: ['./wallet-deposit-withdraw.component.scss']
})
export class WalletDepositWithdrawComponent implements OnInit {
  createid: any;
  withdrawform: FormGroup;
  depositform: FormGroup;
  type: any;
  token: any;
  depositdata:any;
  loginButtonDisable = false;
  modalRef: BsModalRef;
  userdatak:any;
  constructor(private _location: Location,private modalService: BsModalService, private toastr: ToastrService, private usersService: UsersService, private route: ActivatedRoute, private fb: FormBuilder, private router: Router) {
    this.route.paramMap.subscribe(param => {
      this.type = param.get('type') // (+)Converts string 'id' to number
    });
  }

  ngOnInit(): void {
    var data = sessionStorage.getItem('details');
    this.userdatak =  JSON.parse(sessionStorage.getItem('userDetails'));
    this.createid = JSON.parse(data);
    this.token = sessionStorage.getItem("token");
   // console.log(this.createid);
    this.Withdrawform();
    this.Depositform();
    this.getmysiteid(this.createid._id);
  }
  backClicked() {
    // console.log('test')
    this._location.back();
  }

  Withdrawform() {
    this.withdrawform = this.fb.group({
      coins: ['', [Validators.required, Validators.min(500)]],
      withdrawtype: ['', [Validators.required]],
    })
  }
  Depositform() {
    this.depositform = this.fb.group({
      coins: ['', [Validators.required, Validators.min(500)]],
      wallet: [''],
    })
  }

  openmodel(success: TemplateRef<any>) {
    // console.log(this.detaile);
    this.modalRef = this.modalService.show(
      success,
      Object.assign({}, { class: 'success modal-lg' })
    );
  }



  createidform() {
    // console.log(this.withdrawform.value)
    if (this.withdrawform.value.withdrawtype == 'wallet') {
      if (this.withdrawform.value.coins != '' && this.withdrawform.value.coins >= 500) {
        this.withdrawdata();
      }
    } else if (this.withdrawform.value.withdrawtype == 'account'){
      if (this.withdrawform.value.coins != '' && this.withdrawform.value.coins >= 500) {
        var data = JSON.stringify(this.withdrawform.value);
        sessionStorage.setItem('withdrawamount', data)
        this.router.navigate(['wallet-withdraw/' + 'idwithdraw']);
      }
    }

  }
  getmysiteid(iddata) {
    var data = { id: iddata,  user_id:this.userdatak.details._id,}
    this.usersService.walletPost("getMysitesByID", data).subscribe((response: any) => {
       console.log(response);
    })
  }

  iDdepositform() {
    // console.log(this.depositform.value)
    if (this.depositform.value.coins != '' && this.depositform.value.coins >= 500) {
      if (this.depositform.value.wallet == false) {
        var data = JSON.stringify(this.depositform.value);
        sessionStorage.setItem('depositamount', data)
        this.router.navigate(['wallet-deposit/' + 'iddeposit']);
      } else {
        this.submitidform();
      }
    }
  }

  withdrawdata() {
    this.loginButtonDisable = true;
    var data = new FormData();
    data.append('mysiteId', this.createid._id);
    data.append('amount', this.withdrawform.value.coins);
    data.append('type', 'wallet');
    data.append('user_id',this.userdatak.details._id);
   // data.append('managerId', this.createid.sites._id);
//data.append('managertype', this.createid.type);
    this.usersService.walletPost("withdrawalInsites", data).subscribe((response: any) => {
      console.log(response);
      this.depositdata = response;
      if (response) {
        this.loginButtonDisable = false;
        document.getElementById("openModalButton").click();
        this.withdrawform?.reset();
        // this.toastr.success(response.message);
      }
    })
  }


  submitidform() {
    this.loginButtonDisable = true;
    var data = new FormData();
    data.append('type', this.createid.type);
    data.append('typeId', this.createid.typeId);
    data.append('mysiteId', this.createid._id);
    data.append('user_id',this.userdatak.details._id);
    data.append('amount', this.depositform.value.coins);
    data.append('paymentType', 'wallet');
    data.append('image', '');
    data.append('depositId', '');
    this.usersService.walletPost("depositInsite", data).subscribe((response: any) => {
      console.log(response);
      this.depositdata = response;
      if (response) {
        this.loginButtonDisable = false;
        document.getElementById("openModalButton").click();
        this.depositform?.reset();
        // this.toastr.success(response.message);
      }
    })
  }
  // ngOnDestroy(){
  //   sessionStorage.removeItem('details');
  // }

}
