import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../services/users.service';
import casinoDb from '../casinodb.json';
import { Router } from "@angular/router";
import { Location } from '@angular/common';
@Component({
  selector: 'app-casino-url',
  templateUrl: './casino-url.component.html',
  styleUrls: ['./casino-url.component.scss']
})
export class CasinoUrlComponent implements OnInit,OnDestroy {
  gameId:any;
  loader:boolean=false;
  userDetails:any;
  iframUrl:any;
  amountId:any;
  depWith:any;
  casinoBal:any=0;
  walletBalance:any=0;
  amount:string='';
  casino:any;
  dauble: any;
  single: any;
casinolog: any;

  constructor(private socket: Socket, private router: Router, public sanitizer :DomSanitizer,public _location: Location, public httpClient:UsersService,private toastr: ToastrService) {
    this.userDetails=JSON.parse(sessionStorage.getItem('userDetails'));
    this.casino=JSON.parse(localStorage.getItem('casinoDb'));
     this.dauble=this.casino?.gameId;
    this.single=this.casino?.gameId;
   }

  ngOnInit(): void {
    if(this.userDetails){
    this.getUserBalance();
    this.get_qtech();
    this.settings();
    }
  }

  efresh(): void {
    this.router.navigateByUrl("/refresh", { skipLocationChange: true }).then(() => {
      this.router.navigate([decodeURI(this._location.path())]);
    });
    if(sessionStorage.getItem('loginStatus') === "true"){
      this.getUserBalance();
    }
  }
  settings() {
    //this.userDetails = await this.getDetials();
    const data = { user_id: this.userDetails.details._id };
  
    this.httpClient.SocketPost("menusetting", data).subscribe((res: any) => {
      var available_events = res;
      if (available_events) {
       // this.wallet_status=available_events?.manager?.wallet_status;
        var available_event = available_events.eventTypes;
        const available_user = available_events.user.availableEventTypes;
  
        // Map event types to menu variables
        const eventMap = {
          'c9': 'casinolog',
         // 'aviator' : 'aviatorlog'
        };
  
        // Initialize menu visibility
        Object.keys(eventMap).forEach((id) => {
          const menuKey = eventMap[id];
          const isEventAvailable = available_user.includes(id);
          const event = available_event.find((data) => data.eventType.id === id);
          console.log(isEventAvailable);
          this[menuKey] = event?.visible_status && isEventAvailable;
          
        });
      }
    });
  }
  get_qtech() {
    if(this.dauble){
    const userdata = {
      gameId: this.dauble,
      tableId: '',
      username: this.userDetails.details.username
    };
  
    console.warn(userdata);

    this.httpClient.casioPost('singleGame', userdata).subscribe((res: any) => {
      if (res.success) {
        if (res.data.url) {
        //  console.log(res.data.url);
          this.showModal();
          console.log();
          this.iframUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.data.url);

        }
        else {
          this.toastr.error('Game not found!');
        }
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    });
  }
  if(this.single){
    const userdata = {
      gameId: this.single,
      tableId: '',
      username: this.userDetails.details.username
    };
  
   // console.warn(userdata);

    this.httpClient.casioPost('singleGame', userdata).subscribe((res: any) => {
      console.log(res);
      if (res.success) {
        if (res.data.url) {
          console.log(res.data.url);
          this.showModal();
          console.log();
          this.iframUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.data.url);

        }
        // else {
        //   this.toastr.error('Game not found!');
        // }
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    });
  }

  }

  getUserBalance() {
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          token:this.userDetails.verifytoken,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      
      this.socket.emit('get-user', userdata);
      
      this.socket.on('get-user-success',(function(data:any){
        if(data){
          this.walletBalance = data.balance;
          this.getCasinoBal();
        }
       }).bind(this));
      
    }

    getCasinoBal() {
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      this.socket.emit('get-userbalance', userdata);
      
      this.socket.on('get-balance-success',(function(data:any){
        
        if(data){
          this.casinoBal=data.amount;
        }
       }).bind(this));
      
    }

  
    back() {
      this._location.back();
    }
  
    // Show modal function 
    showModal() {
      // Get modal and buttons 
      const modal = document.getElementById('modal');
      modal.style.display = 'flex';
    }
  
    // Hide modal function 
    hideModal() {
      const modal = document.getElementById('modal');
      modal.style.display = 'none';
    }
  ngOnDestroy() {
    localStorage.removeItem('casinoDb');
    this.socket.removeAllListeners();
  }

}
