import { Component, OnInit, ElementRef, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Match } from '../model/match';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '../services/users.service';
import { Socket } from 'ngx-socket-io';
import { ConnectionService } from 'ng-connection-service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bet-slip-bottom-sheet',
  templateUrl: './bet-slip-bottom-sheet.component.html',
  styleUrls: ['./bet-slip-bottom-sheet.component.scss']
})
export class BetSlipBottomSheetComponent implements OnInit, OnDestroy {
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  netConnectService: Subscription;
  isConnected: any;
  userDetails: any;
  betloder: boolean = false;
  userMatchStack: Array<number>;
  public matchModel: Match;
  stakeIds: any;
  stackval: any;
  isActive: boolean;
  total_liability: any;
  config_max_odd_limit: any;
  betslipinfo: boolean;
  betPrice: any;
  arrayObj: any;
  setRef: any;
  disablePlaceBet: boolean = false;
  disableFancyPlaceBet: boolean = false;
  betData: any;
  matchData: any;
  applyUserValidation: boolean;
  betSize: any;
  userStack: any;
  sportsSettingValues: any;
  check_event_limit: any;
  fancyIdValidation: any;
  sessionSetting: any;
  acceptAnyBets: any;
  ring: boolean;
  ringOn: any;
  preBetData: any = [];
  preBet: any;
  status:boolean;
 editstake: any=[1000,5000,10000,25000,50000,100000,200000];
  // editstake:{value1:any;value2:any;value3:any;value4:any;value5:any;}={value1:'' ,value2:'',value3:'',value4:'',value5:''};
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private router: Router, private usersService: UsersService, public toastr: ToastrService,
    private _bottomSheetRef: MatBottomSheetRef<BetSlipBottomSheetComponent>, private socket: Socket, private connectionService: ConnectionService) {
    this.matchModel = this.data.matchModel;
    this.arrayObj = this.data.arrayObj;
    this.betPrice = this.data.betPrice;
    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.status=false;
    // this.betSize = this.data.betSize;
    // this.betData = this.data.betData;
    // this.applyUserValidation = this.data.applyUserValidation;
    // this.sportsSettingValues = this.data.sportsSettingValues;
    // this.check_event_limit = this.data.checkEventLimit;
    // this.fancyIdValidation = this.data.fancyId;
    // this.sessionSetting = this.data.session_setting;
    this.ringOn = this.data.ring_On;
    this.checkIntConn();
  }

  closeSheet(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
    this.ring = false;
  }

  ngOnInit() {
    // this.matchData = JSON.parse(localStorage.getItem('matchData'));
    
    // this.userStack = (localStorage.getItem('matchStack'));
    this.preBetData = this.arrayObj.allBetData;
    this.getstake();
    // console.log(this.preBetData)
    // const userMatchStack1: any = "1000,5000,10000,25000,50000,100000,200000";
    // console.log(userMatchStack1);  
    // this.userMatchStack = userMatchStack1.split(',');
    // console.log(this.userMatchStack);
    let betData = JSON.parse(sessionStorage.getItem('betDataS'));
    this.preBet = betData?.bet;
  }

  checkIntConn() {
    // checkInternetConnection
    this.netConnectService = this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (!this.isConnected) {
        this._bottomSheetRef.dismiss();
      }

    })
    // console.warn('checkConn');

    // this.socket.on('disconnect',(function(data:any){
    //   if(data){
    //     console.warn('disconnect');
    //     this.internetConn=false;
    //   }
    //  }).bind(this));

    //   this.socket.on('connect',(function(data:any){
    //       console.warn('connect');
    //       this.internetConn=true;  
    //  }).bind(this)); 
  }
  getstake(){
    var data ={ user:this.userDetails} 
    this.socket.emit("get-stake" , data);
    this.socket.on("get-stake-success",function (data: any) {
      // console.log(data);
      
        if (data.data.stake_array==0) {
        }else{        
         this.editstake=data.data.stake_array; 
        }
      }.bind(this)
    );
  }
  // updatestake(){
  //   var data ={stake_array:this.editstake , user:this.userDetails}
  //   this.socket.emit("edit-stake" , data);
  //   this.socket.on("edit-stake-success",function (data: any) {
  //     this.status=false
  //       if (data.status==true) {
  //         this.getstake();
  //       }
  //     }.bind(this)
  //   );
  // }
  // onModelChange(event , index){
   
  //   var stake = Number(event.target.value)
  //   this.editstake[index] = stake;
    
    
  // }
  calProLoss(a: any, data: any, index: number, matchOddsData: any) {

    if (a && this.arrayObj.allBetData) {
      let test = this.arrayObj.allBetData.filter(item => {
        return item.marketName == matchOddsData.marketName;
      });

      let betsValue = test.filter(item => {
        if (a.selectionId != undefined) {
          return item.runnerId == a.selectionId;
        } else {
          return item.runnerId == data.runners[index].selectionId;
        }
      });

      let laystaketotal = test.filter(item => {
        if (a.selectionId != undefined) {
          return item.runnerId != a.selectionId;
        } else {
          return item.runnerId != data.runners[index].selectionId;
        }
      });

      let backData = betsValue.filter(item => {
        return item.type == 'Back';
      });

      let layData = betsValue.filter(item => {
        return item.type == 'Lay';
      });

      let oppBack = laystaketotal.filter(item => {
        return item.type == 'Back';
      });

      let totalOppBack = 0;
      oppBack.map(b => {
        totalOppBack = totalOppBack + b.stake;
      });

      let oppLay = laystaketotal.filter(item => {
        return item.type == 'Lay';
      });

      let totalOppLay = 0;
      oppLay.map(b => {
        totalOppLay = totalOppLay + b.stake;
      });


      let backvalue = 0;
      backData.map(b => {
        let back = b.stake * (b.rate - 1);
        backvalue = backvalue + back;
      });

      let layvalue = 0;
      layData.map(b => {
        let lay = b.stake * (b.rate - 1);
        layvalue = layvalue + lay;
      });

      let backtotal = backvalue - totalOppBack;
      let laytotal = totalOppLay - layvalue;

      let markettotal;
      //  if (market === true) 
      //  {
      //   let totalno = backtotal + laytotal;
      //   markettotal = totalno * 37;
      //  } 
      //  else 
      //  {
      //   markettotal = backtotal + laytotal;
      //  }

      markettotal = backtotal + laytotal;

      return (markettotal);
    }

  }

  calProLoss_pre(amount: any, a: any, data: any, index: number, matchOddsData: any) {
    let bet;
    bet = this.preBet;

    if (bet == undefined) return;
    bet.stake = amount;

    let preBetData_v = [];
    let type = "Back";
    if (bet.type == 0) {
      type = "Lay";
    }

    let bet_json = {
      "auto": false,
      "runnerArray": [],
      "_id": "64e598af22faee7e65030acc",
      "username": "OSGCLUB1866",
      "image": "man-4.svg",
      "eventTypeId": "4",
      "eventTypeName": "Cricket",
      "marketId": bet.marketId,
      "marketName": bet.marketName,
      "eventId": bet.eventId,
      "eventName": bet.eventName,
      "runnerId": bet.runnerId,
      "selectionName": bet.selectionName,
      "type": type,
      "stake": parseInt(bet.stake),
      "placedTime": "2023-08-23T05:27:11.851Z",
      "result": "ACTIVE",
      "manager": "OSGCLUB",
      "admin": "admin",
      "subadmin": "OSGSUB",
      "master": "CLUBMASTER",
      "deleted": false,
      "masterCommision": 0,
      "subadminCommision": 0,
      "adminCommision": 5,
      "betentertime": 1692768430,
      "device": {
        "brand": "brand",
        "model": "model",
        "version": "version",
        "ip": "49.43.152.239"
      },
      "rate": bet.rate,
      "status": "MATCHED",
      "serverRate": bet.rate,
      "matchedTime": "2023-08-23T05:27:11.851Z",
      "__v": 0
    }

    if (bet.stake == 0) {

    }
    else {
      let arr = new Array(bet_json);

      preBetData_v = [...arr, ...this.preBetData];
      // console.log(preBetData_v)
    }

    if (a && preBetData_v) {
      let test = preBetData_v.filter(item => {
        return item.marketName == matchOddsData.marketName;
      });

      let betsValue = test.filter(item => {
        if (a.selectionId != undefined) {
          return item.runnerId == a.selectionId;
        } else {
          return item.runnerId == data.runners[index].selectionId;
        }
      });

      let laystaketotal = test.filter(item => {
        if (a.selectionId != undefined) {
          return item.runnerId != a.selectionId;
        } else {
          return item.runnerId != data.runners[index].selectionId;
        }
      });

      let backData = betsValue.filter(item => {
        return item.type == 'Back';
      });

      let layData = betsValue.filter(item => {
        return item.type == 'Lay';
      });

      let oppBack = laystaketotal.filter(item => {
        return item.type == 'Back';
      });

      let totalOppBack = 0;
      oppBack.map(b => {
        totalOppBack = totalOppBack + b.stake;
      });

      let oppLay = laystaketotal.filter(item => {
        return item.type == 'Lay';
      });

      let totalOppLay = 0;
      oppLay.map(b => {
        totalOppLay = totalOppLay + b.stake;
      });


      let backvalue = 0;
      backData.map(b => {
        let back = b.stake * (b.rate - 1);
        backvalue = backvalue + back;
      });

      let layvalue = 0;
      layData.map(b => {
        let lay = b.stake * (b.rate - 1);
        layvalue = layvalue + lay;
      });

      let backtotal = backvalue - totalOppBack;
      let laytotal = totalOppLay - layvalue;

      let markettotal;
      //  if (market === true) 
      //  {
      //   let totalno = backtotal + laytotal;
      //   markettotal = totalno * 37;
      //  } 
      //  else 
      //  {
      //   markettotal = backtotal + laytotal;
      //  }

      markettotal = backtotal + laytotal;

      return (markettotal);
    }

  }

  scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 300), behavior: 'smooth' });
  }

  scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 300), behavior: 'smooth' });
  }

  calculateP_lOnStackOnInput(stake, stkbtn, isback, back) {
    if (stake <= 0) {
      back.stake = 0;
    } else {
      if (this.preBet) {
        this.preBet.stake = stake;
      }


      this.stakeIds = this.matchModel.stakeIds;
      this.stackval = (back.stake == '' || back.stake == null) ? 0 : back.stake;
      back.stake = parseFloat(stkbtn);

      if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
        this.isActive = true;
        back.p_l = ((back.odds * back.stake) - back.stake);
        if (isback == 0) {
          this.total_liability = back.p_l;
        } else {
          this.total_liability = back.stake;

        }
        this.matchModel.calculateProfitLoss(back);
        if (back.priceVal <= this.config_max_odd_limit || back.is_session_fancy == 'Y' || true) {
          if (back.priceVal > 0) {
            const tempback = back;
            this.isActive = true;
            this.total_liability = 0;
            if (back.isManual) {
              const pval = back.pricefinal + 1;
              back.p_l = ((pval * back.stake) - back.stake);
            } else {
              back.p_l = ((back.priceVal * back.stake) - back.stake);
            }
            this.matchModel.ProfitLoss = back.p_l;


          }

        }
      } else {
        let msg = '';
        if (back.is_session_fancy == 'Y') {
          msg = 'Max session bet liability is ' + parseFloat(back.max_bet_liability);
        } else {
          msg = 'Max market bet liability is ' + parseFloat(back.max_bet_liability);
        }
        back.stake = parseFloat(back.max_bet_liability);
        this.isActive = false;
      }
    }

  }
  searchRunner(runners: any[], id: string): any {
    if (!runners) return null;
    for (var key in runners) {
      if (runners[key].selectionId == id)
        return runners[key].runnerName;
    }

  }
  saveBet(betValue) {
    this.betloder = true;
    // if (this.applyUserValidation == true) {
    //   // this.validateStakeValue(betValue[0].stake, betValue[0])
    //   // this.validateStakeAmountwithOldBets(betValue[0].stake, betValue[0])
    // } else {
    //   this.betloder=false;
    // }
    if (!this.disablePlaceBet) {
      if (this.betPrice == betValue[0].odds) {
        // console.log( this.arrayObj.MatchName);
        // bet placeSocket
        let betData = JSON.parse(sessionStorage.getItem('betDataS'));
        this.preBet = betData.bet;
        if (betData.bet.type == 1) {
          betData.bet.type = 'Back';
        }
        else {
          betData.bet.type = 'Lay';
        }
        betData.bet.stake = betValue[0].stake;
        if (this.acceptAnyBets) {
          if (this.acceptAnyBets == 1) {
            betData.bet.acceptany = 1;
          }
        }
        if (betData.bet.stake === 0) {
          this.betloder = false;
        } else {
          this._bottomSheetRef.dismiss();
          this.usersService.updateBetPlaced(this.arrayObj.MatchName);
          setTimeout(() => { this.betSocket(betData); }, 1000);
        }


      } else {
        this._bottomSheetRef.dismiss();
        this.toastr.error('Bet Price is changed , Please try again', '', {
          timeOut: 10000,
        });
        this.betloder = false;
      }
    } else {
      this.betloder = false;
    }
  }

  saveFancyBet(betValue) {
    this.betloder = true;
    // this._bottomSheetRef.dismiss();
    // this.usersService.updateBetPlaced(this.arrayObj.MatchName);
    // if (this.applyUserValidation == true) {
    //   this.validateFancyStakeValue(betValue[0].stake, betValue[0])
    // } else {
    //   this.disableFancyPlaceBet = false;

    // }

    // bet placeSocket
    let betData = JSON.parse(sessionStorage.getItem('betSessionData'));
    if (betData.bet.type == 1) {
      betData.bet.type = 'Back';
    }
    else {
      betData.bet.type = 'Lay';
    }
    betData.bet.stake = betValue[0].stake;
    if (betData.bet.stake === 0) {
      this.betloder = false;
    } else {
      this._bottomSheetRef.dismiss();
      this.usersService.updateBetPlaced(this.arrayObj.MatchName);
      setTimeout(() => { this.betSocket(betData); }, 1000);
    }
    // setTimeout(() => { this.betSocket(betData); }, 1000);
  }

  betSocket(data: any) {
    this.socket.emit('create-bet', data);
    this.socket.on('place-bet-success', (function (data: any) {
      this.toastr.success(data.message);
      this.betloder = false;
      this.removeSocketListner();
      this.getUserBalance();
    }).bind(this));

    this.socket.on('place-bet-error', (function (data: any) {
      this.toastr.error(data.message);
      this.betloder = false;
      this.socket.removeListener('place-bet-error');
    }).bind(this));

    //  this.socket.on('get-user-bets-success', (function(data:any){
    //   this.toastr.success(data.msg, '', {
    //     positionClass: 'toast-bottom-right',
    //     timeOut: 1000
    //   })
    //   this.removeSocketListner();
    //  }).bind(this));

    //  this.socket.on('get-user-details-success', (function(data:any){
    //   this.toastr.success(data.msg, '', {
    //     positionClass: 'toast-bottom-right',
    //     timeOut: 1000
    //   })
    //   this.removeSocketListner();
    //  }).bind(this));

  }

  async getDetials() {
    try {
      const data = await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }

  }

  async getUserBalance() {
    this.userDetails = await this.getDetials();
    const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        token: this.userDetails.verifytoken,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      }
    };

    this.socket.emit('get-user', userdata);

    this.socket.on('get-user-success', (function (data: any) {
      if (data) {
        this.usersService.updateUserBalanceSubject(data);
      }
    }).bind(this));

  }
  clearAll() {
    this.betslipinfo = false;
    this.total_liability = 0;
    this.isActive = false;
    for (let i = 0; i < this.matchModel.stakeIds.length; i++) {
      this.matchModel.stake2['field_' + this.matchModel.stakeIds[i].UId] = 0;
    }
  };

  calculateP_lOnStack(stake, stkbtn, isback, back) {
    if (this.preBet) {
      this.preBet['stake'] = stkbtn;
    }


    this.stakeIds = this.matchModel.stakeIds;
    this.stackval = (back.stake == '' || back.stake == null) ? 0 : back.stake;
    back.stake = parseFloat(stkbtn) + parseFloat(this.stackval);
    if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
      this.isActive = true;
      back.p_l = ((back.odds * back.stake) - back.stake);
      if (isback == 0) {
        this.total_liability = back.p_l;
      } else {
        this.total_liability = back.stake;
      }
      this.matchModel.calculateProfitLoss(back);
      if (back.odds <= this.config_max_odd_limit || back.is_session_fancy == 'Y' || true) {
        if (back.odds > 0) {
          const tempback = back;
          this.isActive = true;
          this.total_liability = 0;
          if (back.isManual) {
            const pval = back.pricefinal + 1;
            back.p_l = ((pval * back.stake) - back.stake);
          } else {
            back.p_l = ((back.odds * back.stake) - back.stake);
          }
          this.matchModel.ProfitLoss = back.p_l;


        }
      }
    } else {
      let msg = '';
      if (back.is_session_fancy == 'Y') {
        msg = 'Max session bet liability is ' + parseFloat(back.max_bet_liability);
      } else {
        msg = 'Max market bet liability is ' + parseFloat(back.max_bet_liability);
      }
      back.stake = parseFloat(back.max_bet_liability);
      this.isActive = false;
    }
  }

  setbtn(indx) {
    this.setRef = indx;
  }

  validateStakeValue(stake, back) {
    this.toastr.clear();
    this.disablePlaceBet = false;
    let profit = back.is_back == 0 ? back.stake : ((back.odds * back.stake) - back.stake) === NaN ? 0 : ((back.odds * back.stake) - back.stake).toFixed(2);
    if (this.sportsSettingValues != undefined) {
      if (this.check_event_limit == true) {
        if (!this.matchData.inplay) {
          if (stake > this.sportsSettingValues[back.market_id].market_advance_bet_stake) {
            this.disablePlaceBet = true
            this._bottomSheetRef.dismiss();
            this.toastr.error('Before inplay stake amount limit is exceded ' + this.sportsSettingValues[back.market_id].market_advance_bet_stake, '', {
              timeOut: 10000,
            });
          }
        }


        // if (profit > this.sportsSettingValues[back.market_id].market_max_profit) {
        //   this.disablePlaceBet = true
        //   this._bottomSheetRef.dismiss();
        //   this.toastr.error('Profit value (' + this.sportsSettingValues[back.market_id].market_max_profit + ') limit is exceded', '', {
        //     timeOut: 10000,
        //   });
        // }
        if (this.sportsSettingValues[back.market_id].market_min_odds_rate > back.odds || back.odds > this.sportsSettingValues[back.market_id].market_max_odds_rate) {
          this.disablePlaceBet = true
          this._bottomSheetRef.dismiss();
          this.toastr.error('Odd Rate value is not in min and max range(' + this.sportsSettingValues[back.market_id].market_min_odds_rate + '-' + this.sportsSettingValues[back.market_id].market_max_odds_rate + ')', '', {
            timeOut: 10000,
          });
        }
        if (this.sportsSettingValues[back.market_id].market_min_stack > stake || stake > this.sportsSettingValues[back.market_id].market_max_stack) {
          this.disablePlaceBet = true
          this._bottomSheetRef.dismiss();
          this.toastr.error('Stake value is not in min and max range(' + this.sportsSettingValues[back.market_id].market_min_stack + '-' + this.sportsSettingValues[back.market_id].market_max_stack + '), please enter valid stake value', '', {
            timeOut: 10000,
          });
        }
      } else {
        if (!this.matchData.inplay) {
          if (stake > this.sportsSettingValues.market_advance_bet_stake) {
            this.disablePlaceBet = true
            this.toastr.error('Before inplay stake amount limit is exceded ' + this.sportsSettingValues.market_advance_bet_stake, '', {
              timeOut: 10000,
            });
          }
        }
        let profit = back.is_back == 0 ? back.stake : ((back.odds * back.stake) - back.stake) == NaN ? 0 : ((back.odds * back.stake) - back.stake).toFixed(2)
        // if (profit > this.sportsSettingValues[this.market].market_max_profit) {
        //   this.disablePlaceBet = true
        //   this.loading =false
        //   this.toastr.error('Profit value (' + this.sportsSettingValues[this.market].market_max_profit + ') limit is exceded', '', {
        //     timeOut: 10000,
        //   });
        // }
        if (this.sportsSettingValues.market_min_odds_rate > back.odds || back.odds > this.sportsSettingValues.market_max_odds_rate) {
          this.disablePlaceBet = true
          this.toastr.error('Odd Rate value is not in min and max range(' + this.sportsSettingValues.market_min_odds_rate + '-' + this.sportsSettingValues.market_max_odds_rate + ')', '', {
            timeOut: 10000,
          });
        }
        if (this.sportsSettingValues.market_min_stack > stake || stake > this.sportsSettingValues.market_max_stack) {
          this.disablePlaceBet = true
          this.toastr.error('Stake value is not in min and max range(' + this.sportsSettingValues.market_min_stack + '-' + this.sportsSettingValues.market_max_stack + '), please enter valid stake value', '', {
            timeOut: 10000,
          });
        }
      }
    } else {
      this.disablePlaceBet = false;
    }

  }

  validateStakeAmountwithOldBets(stakeAmount, back) {
    if (!this.matchData.inplay) {
      if (this.sportsSettingValues != undefined) {
        if (this.check_event_limit != true) {
          if (this.betData !== undefined) {
            let existingBets = this.betData.filter(t => t.market_id == this.matchModel.backlayData.market_id);
            let sumofStake = 0
            if (existingBets.length != 0) {
              existingBets.forEach(element => {
                sumofStake = element.stack + sumofStake
              });
            }
            sumofStake = sumofStake + stakeAmount
            if (sumofStake > this.sportsSettingValues.market_advance_bet_stake) {
              this.disablePlaceBet = true
              this._bottomSheetRef.dismiss();
              this.toastr.error('Market Advance bet stake amount limit is exceded ' + this.sportsSettingValues.market_advance_bet_stake, '', {
                timeOut: 10000,
              });
            }
          }
        } else {
          if (this.betData !== undefined) {
            let existingBets = this.betData.filter(t => t.market_id == this.matchModel.backlayData.market_id);
            let sumofStake = 0
            if (existingBets.length != 0) {
              existingBets.forEach(element => {
                sumofStake = element.stack + sumofStake
              });
            }
            sumofStake = sumofStake + stakeAmount
            if (sumofStake > this.sportsSettingValues[back.market_id].market_advance_bet_stake) {
              this.disablePlaceBet = true
              this._bottomSheetRef.dismiss();
              this.toastr.error('Market Advance bet stake amount limit is exceded ' + this.sportsSettingValues[back.market_id].market_advance_bet_stake, '', {
                timeOut: 10000,
              });
            }
          }
        }
      } else {
        this.disableFancyPlaceBet = false;
        this.disablePlaceBet = false;
      }
    } else {
      this.disablePlaceBet = false;
      this.disableFancyPlaceBet = false;
    }
  }

  validateFancyStakeValue(stake, back) {
    this.toastr.clear();
    this.disableFancyPlaceBet = false
    let profit = back.is_back == 0 ? back.stake : (((back.size * back.stake) / 100).toFixed(2))
    // if (profit > this.sportsSettingValues.session.session_max_profit) {
    //   this.disableFancyPlaceBet = true
    //   this._bottomSheetRef.dismiss();
    //   this.toastr.error('Profit value  (' + this.sportsSettingValues.session.session_max_profit + ') limit is exceded', '', {
    //     timeOut: 10000,
    //   });
    // }


    if (this.sessionSetting != undefined) {
      if (this.check_event_limit != true) {
        if (this.sessionSetting.session_min_stack > stake || stake > this.sessionSetting.session_max_stack) {
          this.disableFancyPlaceBet = true
          this._bottomSheetRef.dismiss();
          this.toastr.error('Stake value is not in min and max range(' + this.sessionSetting.session_min_stack + '-' + this.sessionSetting.session_max_stack + '), please enter valid stake value', '', {
            timeOut: 10000,
          });
        }
      } else {
        if (this.sessionSetting[this.fancyIdValidation].session_min_stack > stake || stake > this.sessionSetting[this.fancyIdValidation].session_max_stack) {
          this.disableFancyPlaceBet = true
          this._bottomSheetRef.dismiss();
          this.toastr.error('Stake value is not in min and max range(' + this.sessionSetting[this.fancyIdValidation].session_min_stack + '-' + this.sessionSetting[this.fancyIdValidation].session_max_stack + '), please enter valid stake value', '', {
            timeOut: 10000,
          });
        }
      }
    }

  }

  acceptAnyBet(event: any) {

    if (event.target.checked == true) {
      this.acceptAnyBets = 1;
    }
    else {
      this.acceptAnyBets = 0;
    }
  }

  removeSocketListner() {
    this.socket.removeAllListeners('');
  }

  ngOnDestroy() {
    this.netConnectService.unsubscribe();
    this.socket.removeListener('place-bet-success');
    this.socket.removeListener('place-bet-error');
    this.socket.removeListener('get-user-success');

  }

}
