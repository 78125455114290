<div id="hidemobheadfoot"> <app-header></app-header></div>
<main id="main" class="full-wrap aviatorgapepage casinoviewpage">
  <div class="backtabmob" style="background: rgb(52, 52, 53);">
    <span [routerLink]="['/home']" class="pagehome pageitem">HOME</span><span [routerLink]="['/intCasino']"
      class="pagecutten pageitem">INT CASINO</span>
  </div>
  <div class="row m-0" *ngIf="this.casinolog===true">
    <div class="col-center col-12">
      <div style="width: 100%;height: 100%;">
        <iframe [src]="iframUrl" *ngIf="iframUrl" marginwidth="0" marginheight="0" frameborder="0" scrolling="yes"
          allowfullscreen="allowfullscreen"></iframe>
      </div>

      <div class="col s12 p-0 main-tabs">
        <!---after slider tabs-->


        <app-right-side-bar></app-right-side-bar>
      </div>
    </div>
  </div>
  <div id="hidemobheadfoot"> <app-footer></app-footer></div>
</main>

<div id="modal" class="modal-container">
  <div class="modal-content">

    <h2 class="font-weight-bold">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> IMPORTANT NOTE
    </h2>
    <p class="confirmation-message font-weight-bold">
      10 Points = 1 Point in Casino Games<br>
      Please Accept Our Terms
    </p>

    <div class="button-container">
      <button (click)="back()" class="button delete-button">
        Cancel
      </button>
      <button (click)="hideModal()" class="button success-button">
        Accept
      </button>
    </div>
  </div>
</div>